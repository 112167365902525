const isProd = process.env.NODE_ENV === 'production';
let cachedMigrationCookieValue;

const isMigrationWarningEnabled = () => {
    if (cachedMigrationCookieValue === undefined) {
        const cookieVals = document.cookie.split(';') || [];
        cachedMigrationCookieValue = cookieVals.some(
            (cookie) => 'SHOW_JQUERY_DEPRECATION_WARNINGS=true' === (cookie && cookie.trim()),
        );
    }
    return cachedMigrationCookieValue;
};

const areJqueryWarningsEnabled = () => {
    return isMigrationWarningEnabled() || !isProd;
};

export default areJqueryWarningsEnabled;
