import $ from 'jquery';
import getWarningFromError from './getWarningFromError';

/**
 * Mimics $.parseJSON() behavior from jQuery <1.9
 * @deprecated
 */

$.veeva_DEPRECATED_parseJSON = function(str) {
    if(str === null || str === undefined || str === '') {
        if ('production' !== process.env.NODE_ENV) {
            // eslint-disable-next-line no-console
            try {
                throw new Error('You are using $.veeva_DEPRECATED_parseJSON(). Migrate your code to JSON.parse() instead.');
            } catch (e) {
                console.warn(getWarningFromError(e));
            }
        }
        return null;
    }
    return JSON.parse(str);
};
