/** @format **/
import 'core-js';
import 'jquery';
import './jquery.veevaDeprecatedParseJSON';
import './jquery.veevaHtmlPrefilter';
// eslint-disable-next-line no-restricted-imports
import underscore from 'underscore';
import backbone from 'backbone';
import '@vault/legacy/widget/jquery-ui';
import '@vault/legacy/widget/jquery.autogrowinput';
import '@vault/legacy/widget/jquery.ba-bbq';
import '@vault/legacy/widget/jquery.ba-outside-events';
import '@vault/legacy/widget/jquery.cookie';
import '@vault/legacy/widget/jquery.sortElements';
import '@vault/legacy/widget/jquery.textinputs';
import '@vault/legacy/widget/jquery.tooltip';
import '@vault/legacy/jmvc';
import '@vault/legacy/widget/jquery.autosize';
import '@vault/legacy/widget/jquery.ui.touch-punch';
import '@vault/legacy/widget/jquery.validate';
import '@vault/legacy/widget/jquery.watermark';

import * as linkifyAll from '@vault/legacy/handlebars/linkify';
import * as messageAll from '@vault/legacy/handlebars/message';
import * as textareaAll from '@vault/legacy/handlebars/textarea';
import * as isAll from '@vault/legacy/handlebars/is';
import * as jsonAll from '@vault/legacy/handlebars/json';

import * as AllReact from 'react';
import * as AllReactdimensions from 'react-dimensions';
import * as AllReactdom from 'react-dom';
import * as AllReacthighlightwords from 'react-highlight-words';
import * as AllReactredux from 'react-redux';
import * as AllReduxLoop from 'redux-loop';
import * as AllRedux from 'redux';
import * as AllCssua from 'cssuseragent';
import * as AllDebug from 'debug';
import * as AllEmotionReact from '@emotion/react';
// the jsx-runtime needs to be a singleton because emotion's theming context is defined there.
// the jsx-dev-runtime is only for an opt-in react setting (when "development" is true), and that would
//   only work if everything was on dev to begin with, which we can't take advantage of because of apps
import * as AllEmotionJSXRuntime from '@emotion/react/jsx-runtime';
import * as AllFocusTrapReact from 'focus-trap-react';
import * as VaultMomentTimeZone from '@vault/moment-timezone';

export default {
    underscore,
    backbone,
    react: AllReact,
    redux: AllRedux,
    'redux-loop': AllReduxLoop,
    'react-dimensions': AllReactdimensions,
    'react-dom': AllReactdom,
    '@emotion/react': AllEmotionReact,
    '@emotion/react/jsx-runtime': AllEmotionJSXRuntime,
    'react-highlight-words': AllReacthighlightwords,
    'react-redux': AllReactredux,
    'focus-trap-react': AllFocusTrapReact,
    cssuseragent: AllCssua,
    debug: AllDebug,
    VaultMomentTimeZone,
    '@vault/legacy/handlebars/linkify': linkifyAll,
    '@vault/legacy/handlebars/message': messageAll,
    '@vault/legacy/handlebars/textarea': textareaAll,
    '@vault/legacy/handlebars/is': isAll,
    '@vault/legacy/handlebars/json': jsonAll,
};
