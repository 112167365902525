import $ from 'jquery';
import areJqueryWarningsEnabled from './jquery.veevaWarnings';
import getWarningFromError from './getWarningFromError';

const rxhtmlTag = /<(?!area|br|col|embed|hr|img|input|link|meta|param)(([a-z][^\/\0>\x20\t\r\n\f]*)[^>]*)\/>/gi;

/**
 * Mimics $.htmlPrefilter() behavior from jQuery <3.5.0
 * @deprecated
 */

$.htmlPrefilter = function(html) {
    if (html.match(rxhtmlTag) && areJqueryWarningsEnabled()) {
        try {
            throw new Error('Do not use self-closing tags as it can cause issues with future jquery versions.');
        } catch (e) {
            console.warn(getWarningFromError(e));
        }
    }
    return html.replace(rxhtmlTag, '<$1></$2>');
};
