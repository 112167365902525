/**
 * Nested map of:
 *  [LocaleLanguageTag]:
 *      [TimeZoneId]:
 *          [Abbreviation]: 'NewAbbreviation'
 * @typedef {Object<string, Object<string, Object<string, string>>>} LocaleAbbreviationOverrides
 *
 * @example
 *  {
 *      'de-ch': {
 *          'Europe/Istanbul': {
 *              'CET': 'EET',
 *              'CEST': 'EEST',
 *          }
 *      }
 *  }
 */

/**
 * Returns a new abbreviation, if overridden
 * @param {import('moment-timezone')} m
 * @param {String} originalAbbr Time zone abbreviation, such as "CET"
 * @param {LocaleAbbreviationOverrides} localeAbbreviationOverrides
 * @returns {[String]} New abbreviation
 */
function getTimeZoneAbbrOverride(m, originalAbbr, localeAbbreviationOverrides) {
    const locale = m.locale();
    const timeZoneName = m._z.name;
    return localeAbbreviationOverrides?.[locale]?.[timeZoneName]?.[originalAbbr];
}

/**
 * Overrides Moment's abbreviation tool to allow us to override specific abbreviations given a locale/time zone
 *  Note: This overrides an internal function that isn't publicly exposed.
 * @param {import('moment-timezone')} moment
 * @param {LocaleAbbreviationOverrides} localeAbbreviationOverrides
 * @returns {import('moment-timezone')}
 */
module.exports = function addLocaleAbbreviationOverrides(moment, localeAbbreviationOverrides) {
    if (!moment?.tz?.Zone) {
        return moment;
    }

    moment.tz.Zone.prototype.abbr = function (m) {
        const originalAbbr = this.abbrs[this._index(m)];

        const abbrOverride = getTimeZoneAbbrOverride(m, originalAbbr, localeAbbreviationOverrides);
        if (abbrOverride) {
            return abbrOverride;
        }

        return originalAbbr;
    };

    return moment;
};
