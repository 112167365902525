/* Do not load the default moment-timezone export, use moment-timezone/moment-timezone instead.
    The default export will load their latest data, which we are overriding with custom data with updated abbreviations 
    that match abbreviations by the server. This is intentional.
*/
const moment = require('moment-timezone/moment-timezone');
const { packedData, localeAbbreviationOverrides } = require('@vault/moment-timezone-data');
moment.tz.load(packedData);

// Vault customizations:
const addJDateFormatParser = require('./moment-jdateformatparser');
const addLocaleAbbreviationOverrides = require('./addLocaleAbbreviationOverrides');

const decoratedMoment = addLocaleAbbreviationOverrides(
    addJDateFormatParser(moment),
    localeAbbreviationOverrides,
);
decoratedMoment.suppressDeprecationWarnings = true;

module.exports = decoratedMoment;
